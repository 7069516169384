.btn-check:checked + .btn-hover,
.btn-hover.active,
.btn-hover.show,
.btn-hover:first-child:active,
:not(.btn-check) + .btn-hover:active {
  color: #fff;
  background-color: #ff7810;
}

.btn-hover:hover {
  color: #fff;
  background-color: #ff7810;
}

.checkbox-color:checked {
  background-color: #ff7810;
  border-color: #ff7810;
  box-shadow: 0px 0px 10px #ff7810;
}

/* for one Liner start */
.oneLiner {
  font-size: 70px;
  line-height: 90px;
}

.oneLiner span {
  color: #ff7810;
  font-size: 85px;
}
/* for one Liner end */

.form-control {
  font-size: 15px;
}

.footer-logo img {
  height: auto;
}

/* landing page */
.landing h1 {
  font-size: 35px;
  line-height: 40px;
}

/* mediaQuery start */
@media (max-width: 992px) {
  .whyChooseUs {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .landing.banner {
    padding: 100px 0 100px !important;
  }
}

@media (max-width: 768px) {
  .mTop {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .oneLiner span {
    font-size: 50px;
  }

  .mTop {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  #header-wrap {
    padding: 15px 0;
  }

  .oneLiner span {
    font-size: 42px;
  }

  .mTopFooter {
    margin-top: 10px;
  }
}

@media (max-width: 425px) {
  .oneLiner span {
    font-size: 32px;
  }

  .page-title h1 {
    font-size: 45px;
    line-height: 65px;
  }
}

@media (max-width: 325px) {
  .navbar-brand.logo {
    width: 210px;
  }
}

/* mediaQuery end */
